import React from 'react';
import styled from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { colors, colorTransparency } from '../../styles/atoms/colors';
import { padding } from '../../styles/atoms/spacing';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';

const Card = ({ slide }) => (
  <Link to={slide?.url} style={{ display: 'block', marginRight: '30px' }}>
    <CardStyled>
      <div className="card-content">
        {slide?.backgroundImage && (
          <Img
            image={slide?.backgroundImage?.gatsbyImageData}
            src={slide?.backgroundImage?.url}
            alt={slide?.backgroundImage?.internalName || ''}
          />
        )}
      </div>
      <div className="overlay">
        {slide?.heading && <h3>{slide.heading}</h3>}
        {slide?.description && (
          <p className="slide-desc">{slide.description}</p>
        )}
      </div>
    </CardStyled>
  </Link>
);

export default Card;

export const CardStyled = styled.div`
  position: relative;
  width: 870px;
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;

  &::before {
    content: '';
    width: 100%;
    padding-top: 56%;
    display: flex;
  }

  &:hover .overlay {
    background: ${colorTransparency(colors.base.black, 0.2)};
  }

  .card-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 140%;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colorTransparency(colors.base.black, 0.5)};
    ${padding(32)}
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: background-color 0.2s ease-in-out;

    h3 {
      color: ${colors.base.white};
      letter-spacing: 0;
      margin-bottom: 8px;
    }

    p {
      font-weight: 400;
      color: ${colors.base.white};

      &.slide-desc {
        display: none;

        ${atMinWidth.md`
          max-width: 440px;
          max-height: calc(100% - 90px);
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        `}
      }
    }

    .card-except {
      word-wrap: break-word;
    }
  }
`;
